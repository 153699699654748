import React from "react"
import { Layout } from "../components"
import { GatsbySeo } from "gatsby-plugin-next-seo"

const Privacy = () => {
  return (
    <Layout>
      <GatsbySeo noindex={true} />
      <div className="max-w-5xl p-4 text-lg">
        <h1>Privacy Verklaring C.G. Klassieke Homeopathie</h1>

        <h2>Uw Privacy</h2>
        <p className="mt-4">
          Voor een goede behandeling is het noodzakelijk dat ik, als uw
          behandelende therapeut, een dossier aanlegt. Dit is ook een wettelijke
          plicht opgelegd door de WGBO. Uw dossier bevat aantekeningen over uw
          gezondheidstoestand en gegevens over de uitgevoerde onderzoeken en
          behandelingen.
        </p>

        <p className="mt-4">
          Ook worden in het dossier gegevens opgenomen die voor uw behandeling
          noodzakelijk zijn en die ik, na uw expliciete toestemming, heb
          opgevraagd bij een andere zorgverlener bijvoorbeeld bij de huisarts.
        </p>

        <p className="mt-4">
          Ik zal uw privacy waarborgen. Dit betekent onder meer dat ik:
          <ul className="ml-4 list-disc">
            <li>Zorgvuldig omga met uw persoonlijke en medische gegevens,</li>
            <li>
              Ervoor zorg dat onbevoegden geen toegang hebben tot uw gegevens
            </li>
          </ul>
        </p>

        <p className="mt-4">
          Als uw behandelende therapeut heb ik als enige toegang tot de gegevens
          in uw dossier. Ik heb een wettelijke geheimhoudingsplicht
          (beroepsgeheim).
        </p>

        <p className="mt-4">
          De gegevens uit uw dossier kunnen ook nog voor de volgende doelen
          gebruikt worden:
          <ul className="ml-4 list-disc">
            <li>
              Om andere zorgverleners te informeren, bijvoorbeeld als de
              therapie is afgerond of bij een verwijzing naar een andere
              behandelaar. Dit gebeurt alleen met uw expliciete toestemming.
            </li>
            <li>Voor het gebruik voor waarneming, tijdens mijn afwezigheid.</li>
            <li>
              Of voor het geanonimiseerde gebruik tijdens intercollegiale
              toetsing.
            </li>
            <li>
              Een klein deel van de gegevens uit uw dossier wordt gebruikt voor
              de financiële administratie, zodat ik of mijn administrateur, een
              factuur kan opstellen. Als ik vanwege een andere reden gebruik wil
              maken van uw gegevens, dan zal ik u eerst informeren en expliciet
              uw toestemming vragen.
            </li>
          </ul>
        </p>

        <p className="mt-4">
          Deze gegevens in het cliëntendossier blijven zoals in de wet op de
          behandelovereenkomst wordt vereist 15 jaar bewaard.
        </p>

        <h2>Privacy op de zorgnota</h2>
        <p className="mt-4">
          Op de zorgnota die u ontvangt staan de gegevens die door de
          zorgverzekeraar gevraagd worden, zodat u deze nota kan declareren bij
          uw zorgverzekeraar.
          <ul className="ml-4 list-disc">
            <li>Uw naam, adres en woonplaats</li>
            <li>Uw geboortedatum</li>
            <li>Uw verzekeringsnummer</li>
            <li>De datum van de behandeling</li>
            <li>
              Een korte omschrijving van de behandeling, zoals bijvoorbeeld
              ‘klassiek
            </li>
            homeopathisch consult’
            <li>De kosten van het consult</li>
          </ul>
        </p>
      </div>
    </Layout>
  )
}

export default Privacy
